<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row match-height" *ngIf="isLoading">
                <div class="col-12 col-lg-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="overlay">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only text-dark">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 text-center">
                    <div class="card">
                        <div class="card-body">
                            <div class="overlay">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only text-dark">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-3">
                    <div class="card">
                        <div class="card-header">
                            <div class="overlay">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only text-dark">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <div class="overlay">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only text-dark">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-3 text-center">
                    <div class="card">
                        <div class="card-header">
                            <div class="overlay">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only text-dark">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <div class="overlay">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only text-dark">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row " *ngFor="let rh of productData;">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12" *ngIf="rh.isLowBalance == 1">
                    <ngb-alert [type]="'danger'" [dismissible]="false">
                        <div class="alert-body">
                            <marquee direction="left" scrolldelay="100" behavior="loop">
                                {{rh.lowBalanceMsg}}
                            </marquee>
                        </div>
                    </ngb-alert>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12"
                    *ngIf="rh.id != 1 && (rh.account_balance == '0.0' && rh.total_liters_ordered != '0.0')">
                    <ngb-alert [type]="'danger'" [dismissible]="false">
                        <div class="alert-body">
                            <marquee direction="left" scrolldelay="100" behavior="loop">
                                {{rh.zeroBalanceMsg}} <a role="button" class="mb-0"
                                    style="color:#026732; font-weight:600;" (click)="moveRechargeHistory(rh.id)"
                                    type="button">Recharge Now</a>
                            </marquee>
                        </div>
                    </ngb-alert>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="row match-height">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-2">
                            <div class="card">
                                <div class="card-body text-center">
                                    <ngb-carousel *ngIf="rh.photo" class="carousel-fade">
                                        <ng-template ngbSlide *ngFor="let rho of rh.photo;">
                                            <div class="carousel-img-wrapper">
                                                <img [src]="rho.product_photo" class="img-fluid"
                                                    alt="Random first slide" />
                                            </div>
                                            <div class="carousel-caption"></div>
                                        </ng-template>
                                    </ngb-carousel>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="text-left">{{rh.product_name}}</h4>
                                    <p class="text-left">{{rh.product_description}}</p>
                                    <button class="btn btn-success mb-1" (click)="moveRechargeHistory(rh.id)"
                                        type="button">Recharge Now</button>
                                    <button class="btn btn-info ml-0 ml-sm-1 mb-1"
                                        (click)="movePastOrderHistory(rh.id)" type="button">Past Order</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-6">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div>
                                                <p class="card-text">Tomorrow's Order</p>
                                                <h2 class="font-weight-bolder mb-0">{{rh.tomorrow_order_qty}}
                                                    Qty</h2>
                                            </div>
                                            <div class="avatar bg-light-primary p-50 m-0">
                                                <a role="button"
                                                    (click)="moveTomorrow(rh.id,rh.tomorrow_order_qty)">
                                                    <div class="avatar-content">
                                                        <i data-feather="edit" class="font-medium-5"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div>
                                                <p class="card-text">Account Balance</p>
                                                <h2 class="font-weight-bolder mb-0">{{rh.account_balance}} Qty
                                                </h2>
                                            </div>
                                            <div class="avatar bg-light-primary p-50 m-0">
                                                <div class="avatar-content">
                                                    <i data-feather="credit-card" class="font-medium-5"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card d-none">
                                        <div class="card-header">
                                            <div>
                                                <p class="card-text">Total Liters Ordered</p>
                                                <h2 class="font-weight-bolder mb-0">{{rh.total_liters_ordered}}
                                                    Qty</h2>
                                            </div>
                                            <div class="avatar bg-light-primary p-50 m-0">
                                                <div class="avatar-content">
                                                    <i data-feather="shopping-cart" class="font-medium-5"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <div>
                                                <p class="card-text  mb-0">Upcoming Vacation</p>
                                                <h2 class="font-weight-bolder mb-0">
                                                    <div *ngIf="rh.upcoming_vacation != null">
                                                        <div class="d-flex flex-wrap align-items-center mt-1">
                                                            <div style="margin-right: 5px;">
                                                                {{rh.upcoming_vacation.start_date | date: 'dd'}}
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                <small style="margin-right: 5px;">
                                                                    {{rh.upcoming_vacation.start_date | date:
                                                                    'MMM'}}
                                                                </small>
                                                           
                                                                <small>
                                                                    {{rh.upcoming_vacation.start_date | date:
                                                                    'yyyy'}}
                                                                </small>
                                                            </div>
                                                            <div class="pr-1 pl-2">
                                                                <i data-feather="minus"></i>
                                                            </div>
                                                            <div style="margin-right: 5px;">
                                                                {{rh.upcoming_vacation.end_date | date: 'dd'}}
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                <small style="margin-right: 5px;">
                                                                    {{rh.upcoming_vacation.end_date | date: 'MMM'}}
                                                                </small>
                                                                <small>
                                                                    {{rh.upcoming_vacation.end_date | date: 'yyyy'}}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </h2>
                                            </div>
                                            <div class="avatar bg-light-primary p-50 m-0">
                                                <a role="button" (click)="moveVacation(rh.id)">
                                                    <div class="avatar-content">
                                                        <i data-feather="settings" class="font-medium-5"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Basic Alerts end -->
        <ng-template #modalSM let-modal>
            <div class="modal-header">
                <h5 class="modal-title" id="myModalLabel160">Recharge Now</h5>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" tabindex="0" ngbAutofocus>
                <div>
                    <p>{{productLowDetails.zeroBalanceMsg}} <a role="button" class="mb-1"
                            style="color:#026732; font-weight:600;" (click)="moveRechargeHistory(productLowDetails.id)"
                            type="button">Recharge Now</a></p>
                </div>
            </div>
        </ng-template>
    </div>
</div>