import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { UserIdleService } from 'angular-user-idle';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from 'app/_services/register.service';

@Component({
  selector: 'app-auth-login-v2',
  templateUrl: './auth-login-v2.component.html',
  styleUrls: ['./auth-login-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthLoginV2Component implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;

  public isLoginForm:boolean = true;
  public isOtpForm:boolean = false;
  public otpForm: FormGroup;
  public otpSubmitted = false;
  public userId:any;
  public otp:any;
  public apiToken:any;
  public loadingOtp:boolean = false;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(private userIdle: UserIdleService,
    private _toastrService: ToastrService,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private authenticationService: AuthenticationService,
    private registerService:RegisterService,

  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => this.timeoutLogout());

    // Start watch when time is up.
    //this.userIdle.onTimeout().subscribe(() => this.timeoutLogout());

    this.loginForm = this._formBuilder.group({
      mobile: ['', [Validators.required]],
      password: ['', Validators.required]
    });

    this.otpForm = this._formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    // Login
    this.submitted = true;
    if (this.loginForm.invalid) {

    }else {
      this.loading = true;
      const { mobile, password } = this.loginForm.controls;
      this.authenticationService.login(mobile.value, password.value).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
              this._router.navigate(['/home']);
              
          }else {
            if(data.status_code == 402){
              this.isLoginForm = false;
              this.isOtpForm = true;
              this.userId = data.result.user_id;
              this.apiToken = data.result.api_token; 
            }else{
              this.error = data.message;
            }
          }
          this.loginForm.reset();
        },err => {
          this._toastrService.error(err.error.message, 'Error!');
        });
    }
  }

  

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  timeoutLogout() {
    this.stop();
    this.stopWatching();
    this._toastrService.clear();
    this._toastrService.warning(
      'Your session has expired.',
      'Opps! Timeout',
      { toastClass: 'toast ngx-toastr', closeButton: true }
    );
    this.authenticationService.logout();
    this._router.navigate(['/login']);
  }

  /*Otp Form Start*/

  get o() {
    return this.otpForm.controls;
  }

  onOtpChange(otp) {
    this.otp = otp;
    if(this.otp.length == 4){
      this.o.otp.setValue(this.otp);
    }else{
      this.o.otp.setValue(undefined);
    }
  }
  

  onSubmitOtp() {
    this.isLoginForm = false;
    this.otpSubmitted = true;
    if (this.otpForm.invalid) {
      if(this.otp == undefined){
        this._toastrService.error("Fill OTP", 'Opps!');
      }
      return;
    }else{
      this.loadingOtp = true;
      this.registerService.verifyOtp(this.userId, this.apiToken,Number(this.o.otp.value)).subscribe(
        data => {
          this.loadingOtp = false;
          if (data.status) {
            this.otpSubmitted = false;
            this.isLoginForm = true;
            this.userId = '';
            this.apiToken = '';
            this.otpForm.reset();
            this.isOtpForm = false;
            //this._router.navigate(['/login']);
            this._toastrService.success(data.message, 'Success!');
          }
          else {
            this._toastrService.error(data.message, 'Success!');
          }
        },err => {
          this._toastrService.error(err.error.message, 'Opps!');
      });
    }
  }
  /*Otp Form End*/

  onReSendOtp(){
    this.registerService.reSendOtp(this.userId,this.apiToken).subscribe(
      data => {
        if (data.status) {
          this._toastrService.success(data.message, 'Success!');
        }
        else {
          this._toastrService.error(data.message, 'Success!');
        }
      },err => {
        this._toastrService.error(err.error.message, 'Opps!');
    });
  }
}
 