import { Component, ElementRef, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { DeliveryOptionsService } from 'app/_services/delivery-options.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ManageVacationService } from 'app/_services/manage-vacation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-vacation',
  templateUrl: './manage-vacation.component.html',
  styleUrls: ['./manage-vacation.component.scss']
})
export class ManageVacationComponent implements OnInit {

  @ViewChild('startPicker') pickerStart;
  @ViewChild('endPicker') pickerEnd;

  @ViewChild('startPickerUpdate') pickerStartUpdate;
  @ViewChild('endPickerUpdate') pickerEndUpdate;


  public isLoading: Boolean = true;
  public isLoadingList: Boolean = true;
  public currentUser: any;
  public selectBasicLoading = true;
  public productData: any = [];
  public vacationList: any;

  public vacationForm: FormGroup;
  public submitted = false;
  public loading = false;
  public productId: any;
  public localproductId: any;
  public s_date: any;
  public e_date: any;
  public isEdit = false;
  public isEditCurrentDate = false;
  public readonlyDate:boolean = false;
  //public productSet:any;
  public vacationId: any;
  //public startOptionsMinDate:'today';


  public startOptions: FlatpickrOptions = {
    altFormat: 'd-m-Y',
    allowInput: true,
    altInput: true,
    minDate: 'today',
  }

  public startPreviousOptions: any;

  public endOptions: FlatpickrOptions = {
    altFormat: 'd-m-Y',
    allowInput: true,
    altInput: true,
    minDate: 'today',
  }

  public startOptionsUpdate: FlatpickrOptions = this.startOptions ;
  public endOptionsUpdate: FlatpickrOptions = this.endOptions ;
  // public startOptionsUpdate: FlatpickrOptions = {
  //   altFormat: 'd-m-Y',
  //   allowInput: true,
  //   altInput: true,
  //   minDate: 'today',
  // }

  // public endOptionsUpdate: FlatpickrOptions = {
  //   altFormat: 'd-m-Y',
  //   allowInput: true,
  //   altInput: true,
  //   minDate: 'today',
  // }

  constructor(private elementRef: ElementRef, private fb: FormBuilder, private _authenticationService: AuthenticationService, private deliverOption: DeliveryOptionsService, private manageVacationService: ManageVacationService, private toastr: ToastrService, private _router: Router, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.currentUser = this._authenticationService.currentUserValue;
    this.vacationForm = this.fb.group({
      product_id: [null, [Validators.required]],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
    });
    this.localproductId = localStorage.getItem('v_product_id');
    this.getProductList();
  //  this.updateStartPreviousOptionsMinDate(new Date());
    this.updateStartOptionsMinDate(new Date());
  }

  

  get f() {
    return this.vacationForm.controls;
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector(
      ".is-invalid"
    );
    firstInvalidControl.focus(); //without smooth behavior
  }

  getProductList() {
    this.deliverOption.getProductData(this.currentUser.result.user_id).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false;
          this.selectBasicLoading = false;
          let arrMy = [];
          for (var i = 0; i < data.result.length; i++) {
            if (data.result[i].is_default == 1) {
              arrMy.push({ id: data.result[i].id, name: data.result[i].product_name, selected_day: data.result[i].selected_day });
            }
          }
          this.productData = arrMy;
          if (this.productData.length > 0) {
            if (this.localproductId == undefined) {
              this.productId = this.productData[0].id;
              this.vacationForm.setValue({
                product_id: this.productData[0].id,
                start_date: '',
                end_date: '',
              });
            } else {
              this.productId = this.localproductId;
              this.vacationForm.setValue({
                product_id: Number(this.localproductId),
                start_date: '',
                end_date: '',
              });
            }
            this.getVacationList();
          }

        } else {
          this.selectBasicLoading = true;
          this.toastr.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectBasicLoading = true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }

  changeProduct(event: any) {
    this.productId = event.id;
    this.isLoadingList = false;
    this.getVacationList();
  }

  getVacationList() {
    this.isLoadingList = true;
    this.manageVacationService.getVacationData(this.currentUser.result.user_id, this.productId).subscribe(
      data => {
        if (data.status) {
          this.vacationList = data.result;
          this.isLoadingList = false;
          localStorage.removeItem('v_product_id')
        }
        else {
          this.isLoadingList = true;
          this.toastr.error(data.message, 'Error!');
        }
      }, err => {
        this.isLoadingList = true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }

  /** On Submit **/
  onSubmit() {
    this.submitted = true;
    if (this.vacationForm.invalid) {
      this.vacationForm.markAllAsTouched();
      setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 100);
    } else {
      this.loading = true;
      let formData = new FormData();

      const currentDate = new Date();
      const timestamp = currentDate.getHours();
      let todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);

      var startDate = new Date(this.convert(this.f.start_date.value));
      startDate.setHours(0, 0, 0, 0);

      let endDate = new Date(this.convert(this.f.end_date.value));
      endDate.setHours(0, 0, 0, 0);

      if (startDate.getTime() > endDate.getTime()) {
        this.toastr.error('End date not greater than start!', 'Error!');
      } else {
        if (13 <= timestamp) {
          if (this.isEdit) {
            let nextDay = new Date(this.s_date);
            nextDay.setDate(new Date().getDate() + 1);
            nextDay.setHours(0, 0, 0, 0);
            if(todayDate <= endDate){
              if(todayDate < startDate){
                if ((nextDay.getTime() >= startDate.getTime()) || (todayDate.getTime() == startDate.getTime())) {
                  this.toastr.error('You can not select past dates!', 'Error!');
                  this.loading = false;
                  return;
                } else {
                  if(todayDate <= startDate){
                    formData.append('start_date', this.convert(this.f.start_date.value));
                    formData.append('end_date', this.convert(this.f.end_date.value));
                  }else{
                    formData.append('start_date', this.convert(this.f.start_date.value));
                    formData.append('end_date', this.convert(this.f.end_date.value));
                  }
                }
              }else{
                formData.append('start_date', this.convert(this.f.start_date.value));
                formData.append('end_date', this.convert(this.f.end_date.value));
              }
            }else{
              if ((nextDay.getTime() >= startDate.getTime()) || (todayDate.getTime() == startDate.getTime())) {
                this.toastr.error('You can not select past date!', 'Error!');
                this.loading = false;
                return;
              } else {
                formData.append('start_date', this.convert(this.f.start_date.value));
                formData.append('end_date', this.convert(this.f.end_date.value));
              }
            }
          } else {
            let nextDay = new Date();
            nextDay.setDate(new Date().getDate() + 1);
            nextDay.setHours(0, 0, 0, 0);
            if ((nextDay.getTime() >= startDate.getTime()) || (todayDate.getTime() == startDate.getTime())) {
              this.toastr.error('You can not select past date!', 'Error!');
              this.loading = false;
              return;
            } else {
              formData.append('start_date', this.convert(this.f.start_date.value));
              formData.append('end_date', this.convert(this.f.end_date.value));
            }
          }
        } else {
          if(this.isEdit) {
            let tomorrowDay = new Date(this.s_date);
            tomorrowDay.setDate(tomorrowDay.getDate() + 1);
            tomorrowDay.setHours(0, 0, 0, 0);
            if(todayDate <= endDate){
              if(todayDate < startDate){
                if ((tomorrowDay.getTime() > startDate.getTime()) || (tomorrowDay.getTime() == startDate.getTime()) || (todayDate.getTime() == startDate.getTime())) {
                  this.toastr.error('You can not select past dates!', 'Error!');
                  this.loading = false;
                  return;
                } else {
                  if(todayDate <= startDate){
                    formData.append('start_date', this.convert(this.f.start_date.value));
                    formData.append('end_date', this.convert(this.f.end_date.value));
                  }else{
                    formData.append('start_date', this.convert(this.f.start_date.value));
                    formData.append('end_date', this.convert(this.f.end_date.value));
                  }
                 
                }
              }else{
                formData.append('start_date', this.convert(this.f.start_date.value));
                formData.append('end_date', this.convert(this.f.end_date.value));
              }
            }else{
              if((tomorrowDay.getTime() >= startDate.getTime()) || (tomorrowDay.getTime() == startDate.getTime()) || (todayDate.getTime() == startDate.getTime())) {
                this.toastr.error('You can not select past date!', 'Error!');
                this.loading = false;
                return;
              } else {
                formData.append('start_date', this.convert(this.f.start_date.value));
                formData.append('end_date', this.convert(this.f.end_date.value));
              }
            }
          } else {
            let tomorrowDay = new Date();
            tomorrowDay.setDate(new Date().getDate() + 1);
            tomorrowDay.setHours(0, 0, 0, 0);
            if ((todayDate.getTime() == startDate.getTime())) {
              this.toastr.error('You can not select past dates!', 'Error!');
              this.loading = false;
              return;
            } else {
              formData.append('start_date', this.convert(this.f.start_date.value));
              formData.append('end_date', this.convert(this.f.end_date.value));
            }
          }
        }
        formData.append('product_id', this.f.product_id.value);
        formData.append('user_id', this.currentUser.result.user_id);
        formData.append('vacation_id', this.vacationId);
        this.manageVacationService.addVacation(formData, this.vacationId).subscribe(data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.vacationId = '';
            this.s_date = '';
            this.e_date = '';
            this.f.product_id.setValue('');
            this.f.start_date.setValue('');
            this.f.end_date.setValue('');

            this.resetForm();
            this.getVacationList();
            if(data.message == "Sorry..! In this date Vacation Already exists."){
              this.toastr.error(data.message, 'Error!');
            }else{
              this.toastr.success(data.message, 'Success!');
            }
            const currentRoute = this._router.url;

            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this._router.navigate(['manage-vacation']); // navigate to same route
            }); 
          }
          else {
            this.toastr.error(data.message, 'Error!');
          }
        }, err => {
          if (err.error.status_code == 400) {
            this.toastr.error(err.error.message, 'Opps!');
          }
        });
      }
    }
  }

  // Reset form and related states
resetForm() {
  this.submitted = false;
  this.vacationId = '';
  this.s_date = '';
  this.e_date = '';
  this.vacationForm.reset();
  this.f.product_id.setValue('');
  this.getVacationList();

  // Refresh the current route
  const currentRoute = this._router.url;
  this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this._router.navigate(['manage-vacation']);
  });
}
  convert(str) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join("-");
  }


  edit(data: any) {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const getTimestamp = currentDate.getHours();
    let endDate = new Date(data.end_date);
    let startDate = new Date(data.start_date);
    //this.updateStartPreviousOptionsMinDate(startDate); // Set initial minDate
    endDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    if (currentDate.getTime() < endDate.getTime()) {
      // if(startDate.getTime() > currentDate.getTime()){
      //   this.isEditCurrentDate = false;
      // }else{
      //   this.isEditCurrentDate = true;
      // }
      this.s_date = data.start_date;
      this.e_date = data.end_date;
      this.vacationForm.setValue({
        product_id: data.product_id,
        start_date: data.start_date,
        end_date: data.end_date,
      });
      this.vacationId = data.id
      this.isLoading = false;
      this.isEdit = true;
      if(startDate < currentDate){
        if (13 <= getTimestamp) {
           this.readonlyDate = false;
        }else{
          this.readonlyDate = true;
        }
      }else{
        this.readonlyDate = false;
      }
      this.updateStartOptionsMinDateUpdate(new Date(data.start_date)) ;
      this.reloadFlatpickr();
    } else {
      this.toastr.error('You can not Edit past vacation!', 'Error!');
    }

  }

  
  updateStartOptionsMinDate(minDate: Date) {
    const currentTodayDate = new Date();
    const timestamp = currentTodayDate.getHours();
    let minStartDateSet = new Date();
    let minEndDateSet = new Date();

    if (13 <= timestamp) {
        minStartDateSet = new Date(currentTodayDate.getFullYear(), currentTodayDate.getMonth(), currentTodayDate.getDate() + 2);
        minEndDateSet = new Date(currentTodayDate.getFullYear(), currentTodayDate.getMonth(), currentTodayDate.getDate() + 2);
    } else {
      if(minDate < currentTodayDate){
        minStartDateSet = minDate;
        minEndDateSet = minDate;
      }else{
        minStartDateSet = new Date(currentTodayDate.getFullYear(), currentTodayDate.getMonth(), currentTodayDate.getDate() + 1);
        minEndDateSet = new Date(currentTodayDate.getFullYear(), currentTodayDate.getMonth(), currentTodayDate.getDate() + 1);
      }
    }
    
    this.startOptions = {
      altFormat: 'd-m-Y',
      allowInput: true,
      altInput: true,
      minDate: minStartDateSet,
    };

    this.endOptions = {
      altFormat: 'd-m-Y',
      allowInput: true,
      altInput: true,
      minDate: minEndDateSet,
    };
    this.reloadFlatpickr();
    this.cd.detectChanges();
  }

  updateStartOptionsMinDateUpdate(minDate: Date) {
    const currentDate = minDate;
    const currentTodayDate = new Date();
    const timestamp = currentTodayDate.getHours();
    let minStartDateSet = new Date();
    let minEndDateSet = new Date();
   
    if (13 <= timestamp) {
      if(minDate > currentTodayDate){
        minStartDateSet = new Date(currentTodayDate.getFullYear(), currentTodayDate.getMonth(), currentTodayDate.getDate() + 2);
        minEndDateSet = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        this.readonlyDate = false;
        console.log(minStartDateSet);
      }else{
        this.readonlyDate = true;
        minStartDateSet = currentTodayDate;
        minEndDateSet = currentDate;
      }
    }else {
      if(minDate < currentTodayDate){
        minStartDateSet = minDate;
        minEndDateSet = minDate;
      }else{
        minStartDateSet = new Date(currentTodayDate.getFullYear(), currentTodayDate.getMonth(), currentTodayDate.getDate() + 1);
        minEndDateSet = new Date(currentTodayDate.getFullYear(), currentTodayDate.getMonth(), currentTodayDate.getDate() + 1);
      }
    }
    
    this.startOptionsUpdate = {
      altFormat: 'd-m-Y',
      allowInput: true,
      altInput: true,
      minDate: minStartDateSet,
    };

    this.endOptionsUpdate = {
      altFormat: 'd-m-Y',
      allowInput: true,
      altInput: true,
      minDate: minEndDateSet,
    };
    
    this.reloadFlatpickrUpdate();
    this.cd.detectChanges();
  }
  

  reloadFlatpickr() {
    if (this.pickerStart && this.pickerStart.flatpickrInstance) {
      this.pickerStart.flatpickrInstance.set(this.startOptions);
    }

    if (this.pickerEnd && this.pickerEnd.flatpickrInstance) {
      this.pickerEnd.flatpickrInstance.set(this.endOptions);
    }
    this.cd.detectChanges();
  }

  reloadFlatpickrUpdate() {
    if (this.pickerStartUpdate && this.pickerStartUpdate.flatpickrInstance) {
      this.pickerStartUpdate.flatpickrInstance.set(this.startOptionsUpdate);
    }
  
    if (this.pickerEndUpdate && this.pickerEndUpdate.flatpickrInstance) {
      this.pickerEndUpdate.flatpickrInstance.set(this.endOptionsUpdate);
    }
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  setNewMinDate(newDate: Date) {
    this.updateStartOptionsMinDate(newDate);
    if(this.isEdit) {
      this.updateStartOptionsMinDateUpdate(newDate);
      this.reloadFlatpickrUpdate();
    }
  }

  // updateStartPreviousOptionsMinDate(minDate: Date) {
  //   const currentDate = new Date();
  //   const timestamp = currentDate.getHours();
  //   let minDateSet = new Date();
  //   let tomorrowDayNew = new Date(minDate);
  //       tomorrowDayNew.getDate() + 1;
  //   if (13 <= timestamp) {
  //     minDateSet = new Date( new Date().getFullYear(),  new Date().getMonth(), new Date().getDate() + 2);
  //   }else{
  //     minDateSet = new Date( new Date().getFullYear(),  new Date().getMonth(), new Date().getDate() + 1);
  //   }
  //   this.startPreviousOptions = {
  //     altFormat: 'd-m-Y',
  //     allowInput: true,
  //     altInput: true,
  //     minDate: minDate, 
  //     disable: [
  //       {
  //         from: tomorrowDayNew,
  //         to: minDateSet
  //       }
  //     ]
  //   };
  // }

  getTodayFormat(fDate:any){
    const today = new Date(fDate);
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }


  deleteData(id: any, start_date: any, end_date: any) {
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    var endDate = new Date(end_date);
    endDate.setHours(0, 0, 0, 0);
    if (currentDate.getTime() <= endDate.getTime()) {
      const me = this;
      const user_id = this.currentUser.result.user_id

      Swal.fire({
        title: 'Are you sure you want delete vacation?',
        html: "From: <b>" + start_date + "</b> To: <b>" + end_date + "</b>",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(function (result) {
        if (result.value) {
          me.manageVacationService.deleteVacation(user_id, id).subscribe(
            data => {
              if (data.status) {
                me.isLoadingList = true;
                me.getVacationList();
                Swal.fire({
                  icon: 'success',
                  title: 'Deleted!',
                  text: data.message,
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                });
              }
              else {
                Swal.fire({
                  icon: 'warning',
                  title: 'Opps!',
                  text: data.message,
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                });
              }
            },
            err => {
              //if (err.error.status_code == 400) {
              this.toastr.error(err.error.message, 'Opps!');
              //}
            }
          );
        }
      });
    } else {
      this.toastr.error('You can not Delete past vacation!', 'Error!');
    }
  }
}
