<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="page-account-settings">
            <div class="row ">
                <div class="col-md-12 col-12">
                    <div class="card">
                        <div class="overlay" *ngIf="isLoading">
                            <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="card-body">
                            <form class="validate-form" [formGroup]="vacationForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                                        <div class="form-group"> 
                                            <label  for="product_id">Select Product</label>
                                            <span class="required" aria-required="true"> * </span>
                                            <ng-select [items]="productData" (change)="changeProduct($event);"  id="product_id" [clearable]="false" [loading]="selectBasicLoading" bindValue="id" bindLabel="name" formControlName="product_id"  placeholder="Select Product" [ngClass]="{ 'is-invalid': submitted && f.product_id.errors }"> </ng-select>
                                            <div *ngIf="submitted && f.product_id.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.product_id.errors }">
                                                <div *ngIf="f.product_id.errors.required">Product is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isEdit == true">
                                        <!-- <div class="form-group" *ngIf="isEditCurrentDate == true"> 
                                            <label  for="start_date">Start Date</label>
                                            <span class="required" aria-required="true"> * </span>
                                            <ng2-flatpickr #startPicker [config]="startOptionsUpdate" [setDate]="s_date"  id="start_date" formControlName="start_date" placeholder="Select Start Date.." [ngClass]="{ 'is-invalid': submitted && f.start_date.errors, 'date-picker-disabled': readonlyDate  }" ></ng2-flatpickr>
                                            <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.start_date.errors }">
                                                <div *ngIf="f.start_date.errors.required">Start date is required</div>
                                            </div>
                                        </div> -->
                                        <div class="form-group"> 
                                            <label  for="start_date">Start Date</label>
                                            <span class="required" aria-required="true"> * </span>
                                            <ng2-flatpickr #startPickerUpdate [config]="startOptionsUpdate" [setDate]="s_date"  id="start_date" formControlName="start_date" placeholder="Select Start Date.." [ngClass]="{ 'is-invalid': submitted && f.start_date.errors, 'date-picker-disabled': readonlyDate }" ></ng2-flatpickr>
                                            <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.start_date.errors }">
                                                <div *ngIf="f.start_date.errors.required">Start date is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isEdit == false">
                                        <div class="form-group"> 
                                            <label  for="start_date">Start Date</label>
                                            <span class="required" aria-required="true"> * </span>
                                            <ng2-flatpickr #startPicker [config]="startOptions" [setDate]="s_date"  id="start_date" formControlName="start_date" placeholder="Select Start Date.." [ngClass]="{ 'is-invalid': submitted && f.start_date.errors, 'date-picker-disabled': readonlyDate }" ></ng2-flatpickr>
                                            <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.start_date.errors }">
                                                <div *ngIf="f.start_date.errors.required">Start date is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isEdit == true">
                                        <div class="form-group"> 
                                            <label  for="end_date">End Date</label>
                                            <span class="required" aria-required="true"> * </span>
                                            <ng2-flatpickr #endPickerUpdate [config]="endOptionsUpdate" [setDate]="e_date" id="end_date" formControlName="end_date" placeholder="Select End Date.." [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }" ></ng2-flatpickr>
                                        </div>
                                        <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.end_date.errors }">
                                            <div *ngIf="f.end_date.errors.required">End date is required</div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isEdit == false">
                                        <div class="form-group"> 
                                            <label  for="end_date">End Date</label>
                                            <span class="required" aria-required="true"> * </span>
                                            <ng2-flatpickr #endPicker [config]="endOptions"  [setDate]="e_date" id="end_date" formControlName="end_date" placeholder="Select End Date.." [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }" ></ng2-flatpickr>
                                        </div>
                                        <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.end_date.errors }">
                                            <div *ngIf="f.end_date.errors.required">End date is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pull-right">
                                    <div class="col-md-12  d-flex">
                                    <button [disabled]="loading" class="btn btn-primary mr-1 " type="submit" rippleEffect>
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                    </button>
                                    <button [disabled]="loading" type="button" routerLink="/home" class="btn btn-secondary mr-1"  rippleEffect>
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12 col-12">
                    <div class="card"  *ngIf="isLoadingList">
                        <div class="card-body">
                            <div class="overlay">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row match-height"  *ngIf="isLoadingList == false && vacationList?.length != 0">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4" *ngFor="let vac of vacationList;">
                            <div class="card">
                                <div class="card-body d-flex justify-content-between align-items-center">
                                    <div>
                                        <i data-feather="calendar" [class]="'text-primary  mr-1'" [size]="18"></i>
                                        <span class="text-secondary mr-1"><b>{{ vac.start_date | date: 'dd-MM-yyyy' }}</b></span>
                                        <i data-feather="star" [class]="'text-primary'" [size]="18"></i>
                                        <span class="text-secondary ml-1"><b>{{ vac.end_date | date: 'dd-MM-yyyy' }}</b></span>
                                    </div>  
                                    <div>
                                        <button type="button" (click)="edit(vac)" class="btn btn-icon btn-flat-success" rippleEffect>
                                            <span [data-feather]="'edit'"></span>
                                        </button>
                                        <button type="button" (click)="deleteData(vac.id,vac.start_date,vac.end_date)" class="btn btn-icon btn-flat-danger" rippleEffect>
                                            <span [data-feather]="'trash-2'"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row match-height"  *ngIf="isLoadingList == false && vacationList?.length == 0">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="card">
                                <div class="card-body text-center">
                                    <h4 class="text-primary mb-0">No Vacation Found</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
      